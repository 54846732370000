import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'


import contact_image from '../assets/images/contact.png'





class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet title="Addictive Pixel" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>


        <div id="main">

          <section id="" className="main">
            <header className="major">
              <h2>General Treatment:</h2>
            </header>



            <p>A rich treatment using light and tone to reveal and showcase the beauty and elegance of the Moley Robotic Kitchen will be applied to the intro of the kitchen information page, and a longer version of the same treatment for the promo video. See below the general treatment video for a guide to how the proposed reveal will look.</p>

            <div className="video-responsive"><iframe src="https://player.vimeo.com/video/380229584" title="380229584" width="640" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe></div>


          </section>


          <section id="" className="main">
            <header className="major">
              <h2>Promo Video:</h2>
            </header>

            <p>We suggest using a mix of cgi and live action, blending the 2 mediums with subtle cuts and dissolves. Until you have or we can shoot the kitchen live, we will use stock live action of food preparation close ups, and then we could swap in the Moley kitchen shots when they are available.
The initial reveal will be sensual and seductive allowing light to reveal the contours of the kitchen, then cuts to rich lifestyle scenarios.
</p>

            <p>Music and a voice over will play a key role, we will present several pieces of music to be discussed and agreed.
</p>

            <div className="video-responsive"><iframe src="https://player.vimeo.com/video/380195275" title="380195275" width="640" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe></div>


          </section>

          <section id="" className="main">
            <header className="major">
              <h2>Kitchen Page Treatment</h2>
            </header>

            <p>We would use your existing CAD data to create the Kitchen intro page, using animation / interactions and info graphics via hot spots on rollover, revealing features and options.</p>

            <p>And again using light to reveal and enhance the contours of the kitchen’s surfaces and textures, while informing the user in detail about the full functionality of the Moley kitchen features.</p>


            <div className="video-responsive"><iframe src="https://player.vimeo.com/video/380224436" title="380224436" width="640" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe></div>


          </section>

        </div>

      </Layout>
    )
  }
}

export default Index
