import React from 'react'

import logo from '../assets/images/AddictivePixel.png';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><a href="/"><img src={logo} alt="" /></a></span>
        {/* <h3>Creative, Media, Content & Digital production</h3>
        <small>Innovative production solutions for the world's leading brands and agencies.</small> */}

    </header>
)

export default Header
